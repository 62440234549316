import React from "react";
import "./Footer.css";

export const Footer = () => {
	return (
		<div className="footercontainer">
			<div className="footerinnercontainer">
				<span>Footer</span>
				{/* <span>Copy rights © All rights served</span> */}
			</div>
		</div>
	);
};
